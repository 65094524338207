<template>
  <div style="display:flex;">
    <div class="data-main-item">
      <img
        class="data-main-img"
        src="@/assets/dataDashboard/累计充电量@2x.png"
        mode="scaleToFill"
      />
      <div style="flex:1">
        <div class="data-main-label">累计充电量</div>
        <div class="data-main-number">
          <count-to
            :endVal="+formatLargeNumber(data.chargeCapacity) || 0"
            :duration="duration"
            :decimals="3"
          />
          <span>{{ getChineseMoneyUnit(data.chargeCapacity).suffix }}度</span>
        </div>
        <div class="data-main-suffix">
          今日充电量
          <span>
            <count-to
              :endVal="+formatLargeNumber(data.todayChargeCapacity) || 0"
              :decimals="3"
              :duration="duration"
            />
            {{ getChineseMoneyUnit(data.todayChargeCapacity).suffix }}度
          </span>
        </div>
      </div>
    </div>
    <div class="data-main-item">
      <img
        class="data-main-img"
        src="@/assets/dataDashboard/累计充电营收@2x.png"
        mode="scaleToFill"
      />
      <div style="flex:1">
        <div class="data-main-label">累计充电营收</div>
        <div class="data-main-number">
          <count-to
            :endVal="+formatLargeNumber(data.chargeAmount) || 0"
            :duration="duration"
            :decimals="2"
          />
          <span>{{ getChineseMoneyUnit(data.chargeAmount).suffix }}元</span>
        </div>
        <div class="data-main-suffix">
          今日充电营收
          <span>
            <count-to
              :endVal="+formatLargeNumber(data.todayChargeAmount) || 0"
              :decimals="2"
              :duration="duration"
            />
            {{ getChineseMoneyUnit(data.todayChargeAmount).suffix }}元</span
          >
        </div>
      </div>
    </div>
    <div class="data-main-item">
      <img
        class="data-main-img"
        src="@/assets/dataDashboard/累计充电服务费@2x.png"
        mode="scaleToFill"
      />
      <div style="flex:1">
        <div class="data-main-label">累计充电服务费</div>
        <div class="data-main-number">
          <count-to
            :endVal="+formatLargeNumber(data.serviceAmount) || 0"
            :duration="duration"
            :decimals="2"
          />
          <span>{{ getChineseMoneyUnit(data.serviceAmount).suffix }}元</span>
        </div>
        <div class="data-main-suffix">
          今日充电服务费
          <span>
            <count-to
              :endVal="+formatLargeNumber(data.todayServiceAmount) || 0"
              :decimals="2"
              :duration="duration"
            />
            {{ getChineseMoneyUnit(data.todayServiceAmount).suffix }}元
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import {
  getChineseMoneyUnit,
  formatLargeNumber,
  formatToThousand,
} from "../utils/unitUtil.js";
import countTo from "vue-count-to";
const props = defineProps({
  data: {
    type: Object,
    default: {},
  },
  duration: {
    type: Number,
    default: 3000,
  },
});
</script>

<style lang="scss" scoped>
.data-main-img {
  width: 120px;
  height: 120px;
}
.data-main-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: center;
  .data-main-number {
    font-family: D-DIN-PRO, D-DIN-PRO Bold;
    font-weight: 600;
    font-size: 32px;
    color: #75e6f3;
    line-height: 46px;
    display: flex;
    align-items: baseline;
    span:first-child {
      display: inline-block;
      // min-width: 100px;
    }
    span:last-child {
      font-size: 16px;
      margin-left: 4px;
      font-weight: 400;
    }
  }
  .data-main-label {
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.85);
    line-height: 28px;
  }
  .data-main-suffix {
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.65);
    margin-top: 10px;
    line-height: 19px;
    span {
      font-weight: 600;
      font-size: 16px;
      color: #75e6f3;
      line-height: 19px;
    }
  }
}
.panel-main-number {
  font-family: D-DIN, D-DIN-PRO;
  font-weight: 600;
  font-size: 26px;
  color: #75e6f3;
  line-height: 40px;
}
.panel-main-suffix {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 16px;
}
</style>
