<template>
  <div class="base-container">
    <VisualizedDashboardContainer>
      <el-container class="container">
        <div class="modal-bg"></div>

        <transition name="fade">
          <div class="map-bg" v-show="!plotId"></div>
        </transition>
        <div class="bottom-bg">
          <div
            style="height: 100%;width: 100%;position: relative"
            v-if="!plotId"
          >
            <div class="bottom-bg-top"></div>
            <div class="bottom-bg-right"></div>
            <div class="bottom-bg-bottom"></div>
            <div class="bottom-bg-left"></div>
          </div>
          <div
            v-else
            style="height: 100%;width: 100%;position: relative;background-color: rgba(3, 18, 22, 0.40);"
          ></div>
        </div>
        <div class="header">
          <div style="position: absolute;left: 24px;top: 13px;display: flex;">
            <div
              class="data-selector"
              v-if="!isOperator"
              style="margin-right: 20px"
            >
              <el-select
                v-model="operId"
                placeholder="运营商名称"
                filterable
                clearable
                popper-class="popper-class"
                :popper-append-to-body="false"
                @change="handleOperChange"
                @clear="handleOperClear"
              >
                <el-option
                  v-for="item in operatorList"
                  :key="item.operatorId"
                  :label="item.operatorNm"
                  :value="item.operatorId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="data-selector">
              <el-select
                v-model="plotId"
                placeholder="站点名称"
                filterable
                clearable
                popper-class="popper-class"
                :popper-append-to-body="false"
                @change="handlePlotChange"
                @clear="handlePlotClear"
              >
                <el-option
                  v-for="item in plotList"
                  :key="item.id"
                  :label="item.plotName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <el-container>
          <el-aside width="477px" style="overflow: visible;padding-left: 24px;">
            <BorderBox style="margin-bottom: 20px" title="经营总览">
              <el-row
                class="flex flex-wrap"
                style="align-items: center;height: 100%;padding: 10px 10px 20px 10px"
              >
                <el-col :span="8" class="flex-y flex-center">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.stationTotal || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">电站总数 (个)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center" v-if="!plotId">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.chargeTerminal || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">充电终端 (个)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center" v-if="!plotId">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.acTerminal || 0"
                      :duration="duration"
                    />/
                    <count-to
                      :endVal="+overviewInfo.dcTerminal || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">交流/直流终端 (个)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center" v-if="plotId">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.acTerminal || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">交流终端 (个)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center" v-if="plotId">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.dcTerminal || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">直流终端 (个)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.chargeServiceCount || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">累计充电服务 (次)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.serviceCount || 0"
                      :duration="duration"
                    />
                  </div>
                  <div class="panel-main-suffix">累计服务车主 (人)</div>
                </el-col>
                <el-col :span="8" class="flex-y flex-center">
                  <div class="panel-main-number">
                    <count-to
                      :endVal="+overviewInfo.reduceCarbon || 0"
                      :duration="duration"
                      :decimals="2"
                    />
                  </div>
                  <div class="panel-main-suffix">减少碳排放 (吨)</div>
                </el-col>
              </el-row>
            </BorderBox>
            <BorderBox
              height="337px"
              style="margin-bottom: 20px"
              title="充电趋势"
              showFilter
              :filterGroup="todayFilterGroups"
              v-loading="chartChargingTrendLoading"
              element-loading-background="rgba(3, 18, 22, 0.4)"
              @filterChange="handleChargingTrendFilterChange"
            >
              <v-chart
                ref="chartChargingTrend"
                style="height: 100%;"
                :option="chartChargingTrendOption"
                theme="dataPanelTheme"
                autoresize
                :manual-update="true"
              />
            </BorderBox>
            <BorderBox
              height="337px"
              title="充电时段分布"
              :filterGroup="lastDayFilterGroups"
              v-loading="chartChargingPeriodsDistributionLoading"
              element-loading-background="rgba(3, 18, 22, 0.4)"
              showFilter
              @filterChange="handleChargingPeriodsDistributionFilterChange"
            >
              <v-chart
                ref="chartChargingPeriodsDistribution"
                style="height: 100%;"
                :option="chartChargingPeriodsDistributionOption"
                theme="dataPanelTheme"
                autoresize
                :manual-update="true"
              />
            </BorderBox>
          </el-aside>
          <el-container>
            <el-main style="display: flex;flex-direction: column; z-index: 1;">
              <div style="flex:1;width: 100%;height: 100%;">
                <accumulatePanel
                  v-if="!plotId"
                  style="display:flex;padding-top: 31px;margin: 0 25px;"
                  :data="overviewInfo"
                ></accumulatePanel>
                <div style="display:flex;" v-else>
                  <BorderBox
                    style="margin-bottom: 20px;margin-top: -20px;width: 100%;"
                    :showHeader="false"
                  >
                    <accumulate-panel
                      style="display:flex;align-items: center;height: 100%;margin: 0 24px"
                      :data="overviewInfo"
                    ></accumulate-panel>
                  </BorderBox>
                </div>
                <div style="display:flex;width: 100%;" v-if="plotId">
                  <BorderBox
                    style="margin-bottom: 20px;width: 100%"
                    height="337px"
                    title="经营效率"
                    showFilter
                    :filterGroup="lastDayFilterGroups"
                    @filterChange="handleBusinessEfficiencyFilterChange"
                  >
                    <div
                      style="width:100%;height: 100%;padding: 20px 0;"
                      class="flex flex-wrap"
                    >
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/时间利用率@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.timeUtilization || 0
                              "
                              :decimals="1"
                              :duration="duration"
                            />
                            <span>%</span>
                          </div>
                          <div class="data-main-suffix">时间利用率</div>
                        </div>
                      </div>
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/单均充电量@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.orderAvgCharge || 0
                              "
                              :decimals="3"
                              :duration="duration"
                            />
                            <span></span>
                          </div>
                          <div class="data-main-suffix">单均充电量(度)</div>
                        </div>
                      </div>
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/枪日均充电量@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.gunAvgCharge || 0
                              "
                              :decimals="3"
                              :duration="duration"
                            />
                            <span></span>
                          </div>
                          <div class="data-main-suffix">枪日均充电量(度)</div>
                        </div>
                      </div>
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/功率利用率@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.powerUtilization || 0
                              "
                              :decimals="1"
                              :duration="duration"
                            />
                            <span>%</span>
                          </div>
                          <div class="data-main-suffix">功率利用率</div>
                        </div>
                      </div>
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/单均服务费@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.orderAvgService || 0
                              "
                              :decimals="2"
                              :duration="duration"
                            />
                            <span></span>
                          </div>
                          <div class="data-main-suffix">单均服务费(元)</div>
                        </div>
                      </div>
                      <div class="data-main-item">
                        <img
                          class="data-mini-img"
                          src="@/assets/dataDashboard/枪日均服务费@2x.png"
                          mode="scaleToFill"
                        />
                        <div>
                          <div class="data-main-number">
                            <count-to
                              :endVal="
                                +bussinessEfficiencyData.gunAvgService || 0
                              "
                              :decimals="2"
                              :duration="duration"
                            />
                            <span></span>
                          </div>
                          <div class="data-main-suffix">枪日均服务费(元)</div>
                        </div>
                      </div>
                    </div>
                  </BorderBox>
                </div>
              </div>
              <div
                style="height: 337px;display: flex;justify-content: space-between;"
              >
                <BorderBox
                  height="337px"
                  title="充电量分布"
                  showFilter
                  :filterGroup="monthFilterGroups"
                  v-loading="chartChargingCapacityLoading"
                  element-loading-background="rgba(3, 18, 22, 0.4)"
                  @filterChange="handleChargingCapacityFilterChange"
                >
                  <v-chart
                    ref="chartChargingCapacity"
                    style="height: 100%;"
                    :option="chartChargingCapacityOption"
                    theme="dataPanelTheme"
                    autoresize
                    :manual-update="true"
                  />
                </BorderBox>

                <BorderBox
                  height="337px"
                  title="订单金额分布"
                  :filterGroup="monthFilterGroups"
                  v-loading="chartOrderPriceDistributionLoading"
                  element-loading-background="rgba(3, 18, 22, 0.4)"
                  showFilter
                  @filterChange="handleOrderPriceDistributionFilterChange"
                >
                  <v-chart
                    ref="chartOrderPriceDistribution"
                    style="height: 100%;"
                    :option="chartOrderPriceDistributionOption"
                    theme="dataPanelTheme"
                    autoresize
                    :manual-update="true"
                  />
                </BorderBox>
              </div>
            </el-main>
          </el-container>
          <el-aside width="477px" style="padding-right: 24px">
            <BorderBox style="margin-bottom: 20px" title="实时终端状态">
              <v-chart
                ref="chartRealtimeTerminalStatus"
                style="height: 100%;"
                :option="chartRealtimeTerminalStatusOption"
                theme="dataPanelTheme"
                autoresize
                :manual-update="true"
              />
            </BorderBox>
            <BorderBox
              height="337px"
              style="margin-bottom: 20px"
              title="功率趋势"
              showFilter
              :filterGroup="todayFilterGroups"
              v-loading="chartPowerTrendLoading"
              @filterChange="handlePowerTrendFilterChange"
              element-loading-background="rgba(3, 18, 22, 0.4)"
            >
              <v-chart
                ref="chartPowerTrendRef"
                style="height: 100%;"
                :option="chartPowerTrendOption"
                theme="dataPanelTheme"
                autoresize
                :manual-update="true"
              />
            </BorderBox>
            <BorderBox
              height="337px"
              v-loading="rankListLoading"
              element-loading-background="rgba(3, 18, 22, 0.4)"
              :filterGroup="lastDayFilterGroups"
              title="运营效率"
              showFilter
              v-if="!plotId"
              @filterChange="handleRankListFilterChange"
            >
              <RankList :data="rankList" ref="rankListRef"></RankList>
            </BorderBox>
            <BorderBox
              height="337px"
              v-loading="rankListLoading"
              element-loading-background="rgba(3, 18, 22, 0.4)"
              title="车主充电频次"
              showFilter
              v-else
              @filterChange="handleChargingFrequencyFilterChange"
            >
              <v-chart
                ref="chartChargingFrequency"
                style="height: 100%;"
                :option="chartChargingFrequencyOption"
                theme="dataPanelTheme"
                autoresize
                :manual-update="true"
              />
            </BorderBox>
          </el-aside>
        </el-container>
        <el-footer class="footer"></el-footer>
      </el-container>
    </VisualizedDashboardContainer>
  </div>
</template>

<script setup name="aggregatedDataDashboard">
import VisualizedDashboardContainer from '@/components/VisualizedDashboardContainer/index.vue';
import accumulatePanel from './components/accumulatePanel.vue';
import RankList from './components/rankList.vue';
import BorderBox from './components/border-box.vue';
import { ref, reactive, provide, onMounted, computed, onUnmounted } from 'vue';
import countTo from 'vue-count-to';
import VChart from 'vue-echarts';
import { use, registerTheme } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import { useChartOption } from './hooks/useChartOption';
import { getName } from '@/utils/auth';
import { getOperatorSelect } from '@/api/operator';
import { getPlotAllList } from '@/api/plot';
import { omit } from 'lodash-es';
import {
  getOperationEfficiency,
  getChargingBehavior,
  getPowerTrend,
  getOverview,
  getPowerUtilization,
  getChargingFrequency,
  getBusinessEfficiency,
} from '@/api/index';
import {
  getManageTrendRevenue,
  getManageChargeTime,
  getManageGunStatus,
} from '@/api/statistics';
let theme = require('@/styles/echarts/dataPanel.json');

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DatasetComponent,
  LineChart,
  BarChart,
]);
registerTheme('dataPanelTheme', theme);

const duration = 3000;
// 运营商id
const operId = ref('');
// 站点id
const plotId = ref('');

const {
  getChartChargingTrendOption,
  getChartChargingPeriodsDistributionOption,
  getChartChargingCapacityOption,
  getChartOrderPriceDistributionOption,
  getChartPowerTrendOption,
  getChartRealtimeTerminalStatusOption,
  getChartChargingFrequencyOption,
} = useChartOption();
// onMounted(() => {
//   console.log(chartChargingTrend.value.chart);
// });
//

const lastDayFilterGroups = ref([
  {
    label: '昨天',
    value: '-1',
    unit: 0,
  },
  {
    label: '近7天',
    value: '-6',
    unit: 0,
  },
  {
    label: '近30天',
    value: '-29',
    unit: 0,
  },
]);
const todayFilterGroups = ref([
  {
    label: '今天',
    value: '0',
    unit: 0,
  },
  {
    label: '近7天',
    value: '-6',
    unit: 0,
  },
  {
    label: '近30天',
    value: '-29',
    unit: 0,
  },
]);
const monthFilterGroups = ref([
  {
    label: '近30天',
    value: '-29',
    unit: 0,
  },
  {
    label: '近12个月',
    value: '-11',
    unit: 1,
  },
]);
// 获取运营商名称
let operatorList = ref([]);
getOperatorSelect().then((res) => {
  operatorList.value = res.data || [];
});

// 获取运营商权限(是否运营商)
let isOperator = computed(() => {
  try {
    let name = JSON.parse(getName());
    return name.userInfo.isOperator === 1;
  } catch (error) {}
});

// 获取站点id
let plotList = ref([]);
getPlotAllList().then((res) => {
  plotList.value = res.data || [];
});

const handleOperChange = () => {
  let getPlotAllListParams = {
    userId: operId.value || undefined,
  };
  getPlotAllList(getPlotAllListParams).then((res) => {
    plotList.value = res.data || [];
  });

  plotId.value = '';

  getOverviewInfo();
  getRankList();
  getChargingTrend();
  getChargingPeriodsDistribution();
  getChargingCapacity();
  getOrderPriceDistribution();
  getRealTimeStatus();
  getPowerTrendData();
};
const handleOperClear = () => {
  plotId.value = '';
};

const handlePlotChange = (e) => {
  getOverviewInfo();
  getRankList();
  getChargingTrend();
  getChargingPeriodsDistribution();
  getChargingCapacity();
  getOrderPriceDistribution();
  getRealTimeStatus();
  getPowerTrendData();
  if (e) {
    getChargingFrequencyData();
    getBusinessEfficiencyData();
  }
};

// 轮询
const dataPollingTimer = setInterval(() => {
  if (plotId.value) {
    handlePlotChange(plotId.value);
  } else {
    handleOperChange();
  }
}, 5000);

onUnmounted(() => {
  clearInterval(dataPollingTimer);
});

const handlePlotClear = () => {
  handlePlotChange();
};

// 获取经营总览info
const overviewInfo = ref({});
const getOverviewInfo = () => {
  let params = {
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
  };
  getOverview(params).then((res) => {
    overviewInfo.value = res.data || {};
  });
};
getOverviewInfo();

// 获取运营效率排名
let rankList = ref([]);
const rankListLoading = ref(false);
let rankDateParam = ref({ value: '-1', unit: 0 });
const getRankList = (param) => {
  let params = {
    ...rankDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  // rankListLoading.value = true;
  getOperationEfficiency(params)
    .then((res) => {
      rankListLoading.value = false;
      rankList.value = res.data || [];
    })
    .catch((e) => {
      rankListLoading.value = false;
    });
};
getRankList();

let rankListRef = ref();
const handleRankListFilterChange = (e) => {
  let param = omit(e, ['label']);
  rankDateParam.value = param;
  getRankList(param);
  rankListRef.value.resetCarousel();
};

// 获取充电趋势
let chartChargingTrendLoading = ref(false);
let chartChargingTrendOption = ref(getChartChargingTrendOption({}));
const chartChargingTrendDateParam = ref({ value: '0', unit: 0 });
const chartChargingTrend = ref();
const getChargingTrend = (param, reload = false) => {
  let params = {
    ...chartChargingTrendDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  // chartChargingTrendLoading.value = true;
  getManageTrendRevenue(params)
    .then((res) => {
      chartChargingTrendLoading.value = false;
      chartChargingTrendOption.value = getChartChargingTrendOption(
        res.data || {}
      );
      if (reload) {
        chartChargingTrend.value.clear();
      }
      chartChargingTrend.value.setOption(chartChargingTrendOption.value);
    })
    .catch((e) => {
      chartChargingTrendLoading.value = false;
    });
};
const handleChargingTrendFilterChange = (e) => {
  let param = omit(e, ['label']);
  chartChargingTrendDateParam.value = param;
  getChargingTrend(omit(e, ['label']), true);
};

getChargingTrend({}, false);

// 充电时段分布
let chartChargingPeriodsDistributionLoading = ref(false);
let chartChargingPeriodsDistributionOption = ref(
  getChartChargingPeriodsDistributionOption({})
);
const chartChargingPeriodsDistribution = ref();
const chartChargingPeriodsDistributionDateParam = ref({
  value: '-1',
  unit: 0,
});
const getChargingPeriodsDistribution = (param, reload = false) => {
  let params = {
    ...chartChargingPeriodsDistributionDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  // chartChargingPeriodsDistributionLoading.value = true;
  getManageChargeTime(params)
    .then((res) => {
      chartChargingPeriodsDistributionLoading.value = false;
      chartChargingPeriodsDistributionOption.value = getChartChargingPeriodsDistributionOption(
        res.data || {}
      );
      if (reload) {
        chartChargingPeriodsDistribution.value.clear();
      }
      chartChargingPeriodsDistribution.value.setOption(
        chartChargingPeriodsDistributionOption.value
      );
    })
    .catch((e) => {
      chartChargingPeriodsDistributionLoading.value = false;
    });
};
const handleChargingPeriodsDistributionFilterChange = (e) => {
  let params = omit(e, ['label']);
  chartChargingPeriodsDistributionDateParam.value = params;
  getChargingPeriodsDistribution(params, true);
};

getChargingPeriodsDistribution({}, false);

// 充电量分布
const chartChargingCapacityOption = ref(getChartChargingCapacityOption({}));
let chartChargingCapacityLoading = ref(false);
const chartChargingCapacityDateParam = ref({
  value: '-29',
  unit: 0,
});
const chartChargingCapacity = ref();
const getChargingCapacity = (param, reload = false) => {
  let params = {
    ...chartChargingCapacityDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    orderType: 0,
    ...param,
  };
  // chartChargingCapacityLoading.value = true;
  getChargingBehavior(params)
    .then((res) => {
      chartChargingCapacityLoading.value = false;
      chartChargingCapacityOption.value = getChartChargingCapacityOption(
        res.data || {}
      );
      if (reload) {
        chartChargingCapacity.value.clear();
      }
      chartChargingCapacity.value.setOption(chartChargingCapacityOption.value);
    })
    .catch((e) => {
      chartChargingCapacityLoading.value = false;
    });
};
const handleChargingCapacityFilterChange = (e) => {
  let param = omit(e, ['label']);
  chartChargingCapacityDateParam.value = param;
  getChargingCapacity(param, true);
};
getChargingCapacity({}, false);

// 订单金额分布
const chartOrderPriceDistributionOption = ref(
  getChartOrderPriceDistributionOption({})
);
let chartOrderPriceDistributionLoading = ref(false);
const chartOrderPriceDistributionDateParam = ref({
  value: '-29',
  unit: 0,
});
const chartOrderPriceDistribution = ref();
const getOrderPriceDistribution = (param, reload = false) => {
  let params = {
    ...chartOrderPriceDistributionDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    orderType: 1,
    ...param,
  };
  // chartOrderPriceDistributionLoading.value = true;
  getChargingBehavior(params)
    .then((res) => {
      chartOrderPriceDistributionLoading.value = false;
      chartOrderPriceDistributionOption.value = getChartOrderPriceDistributionOption(
        res.data || {}
      );
      if (reload) {
        chartOrderPriceDistribution.value.clear();
      }
      chartOrderPriceDistribution.value.setOption(
        chartOrderPriceDistributionOption.value
      );
    })
    .catch((e) => {
      chartOrderPriceDistributionLoading.value = false;
    });
};
const handleOrderPriceDistributionFilterChange = (e) => {
  let param = omit(e, ['label']);
  chartOrderPriceDistributionDateParam.value = param;
  getOrderPriceDistribution(param, true);
};
getOrderPriceDistribution({}, false);

// 实时终端状态
const chartRealtimeTerminalStatusOption = ref(
  getChartRealtimeTerminalStatusOption({})
);
let chartRealTimeStatusLoading = ref(false);
const chartRealtimeTerminalStatus = ref();
const getRealTimeStatus = (param, reload = false) => {
  let params = {
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  // chartRealTimeStatusLoading.value = true;
  getManageGunStatus(params)
    .then((res) => {
      chartRealTimeStatusLoading.value = false;
      chartRealtimeTerminalStatusOption.value = getChartRealtimeTerminalStatusOption(
        res.data || {}
      );
      if (reload) {
        chartRealtimeTerminalStatus.value.clear();
      }
      chartRealtimeTerminalStatus.value.setOption(
        chartRealtimeTerminalStatusOption.value
      );
    })
    .catch((e) => {
      chartRealTimeStatusLoading.value = false;
    });
};

getRealTimeStatus({}, false);

// 功率趋势
const chartPowerTrendOption = ref(getChartPowerTrendOption({}));
let chartPowerTrendLoading = ref(false);
const chartPowerTrendDateParam = ref({
  value: '0',
  unit: 0,
});
const chartPowerTrendRef = ref();
const getPowerTrendData = (param, reload = false) => {
  let params = {
    ...chartPowerTrendDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    orderType: 0,
    ...param,
  };
  // chartPowerTrendLoading.value = true;
  getPowerTrend(params)
    .then((res) => {
      chartPowerTrendLoading.value = false;
      chartPowerTrendOption.value = getChartPowerTrendOption(
        res.data || {},
        params
      );

      if (reload) {
        chartPowerTrendRef.value.clear();
      }
      chartPowerTrendRef.value.setOption(chartPowerTrendOption.value);
    })
    .catch((e) => {
      chartPowerTrendLoading.value = false;
    });
};

const handlePowerTrendFilterChange = (e) => {
  let param = omit(e, ['label']);
  chartPowerTrendDateParam.value = param;
  getPowerTrendData(param, true);
};
getPowerTrendData();

// 车主充电频次
const chartChargingFrequencyOption = ref(getChartChargingFrequencyOption({}));
let chartChargingFrequencyLoading = ref(false);
const chartChargingFrequency = ref();
const chartChargingFrequencyDateParam = ref({
  value: '-6',
  unit: 0,
});
const getChargingFrequencyData = (param, reload = false) => {
  let params = {
    ...chartChargingFrequencyDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  // chartChargingFrequencyLoading.value = true;
  getChargingFrequency(params)
    .then((res) => {
      chartChargingFrequencyLoading.value = false;
      chartChargingFrequencyOption.value = getChartChargingFrequencyOption(
        res.data || []
      );
      if (reload) {
        chartChargingFrequency.value.clear();
      }
      chartChargingFrequency.value.setOption(
        chartChargingFrequencyOption.value
      );
    })
    .catch((e) => {
      chartChargingFrequencyLoading.value = false;
    });
};

const handleChargingFrequencyFilterChange = (e) => {
  let param = omit(e, ['label']);
  chartChargingFrequencyDateParam.value = param;
  getChargingFrequencyData(param, true);
};

getChargingFrequency();

// 经营效率
const bussinessEfficiencyData = ref({});
const bussinessEfficiencyLoading = ref(false);
const bussinessEfficiencyDateParam = ref({
  value: '-1',
  unit: 0,
});
const getBusinessEfficiencyData = (param) => {
  let params = {
    ...bussinessEfficiencyDateParam.value,
    userId: operId.value || undefined,
    plotId: plotId.value || undefined,
    ...param,
  };
  getBusinessEfficiency(params)
    .then((res) => {
      bussinessEfficiencyLoading.value = false;
      bussinessEfficiencyData.value = res.data || {};
    })
    .catch((e) => {
      bussinessEfficiencyLoading.value = false;
    });
};

const handleBusinessEfficiencyFilterChange = (e) => {
  bussinessEfficiencyDateParam.value = omit(e, ['label']);
  getBusinessEfficiencyData(omit(e, ['label']));
};
</script>

<style lang="scss" scoped>
.data-selector {
  padding: 1px;
  border-radius: 4px;
  background-image: linear-gradient(
    90deg,
    rgba(69, 227, 237, 0.65),
    rgba(69, 227, 237, 0.2),
    rgba(69, 227, 237, 0.2),
    rgba(69, 227, 237, 0.65)
  );
  ::v-deep .el-select .el-input.el-input--suffix > input {
    background: #031e24;
    border: none;
    border-radius: 4px;
  }

  ::v-deep .el-select .el-select__caret {
    color: #69efff;
  }
  ::v-deep .el-input__inner {
    color: #69efff;
  }
  ::v-deep .el-input__inner::placeholder {
    color: #69efff;
  }
}
::v-deep .popper-class {
  top: auto !important;
  left: auto !important;
  background-color: #031e24;
  border-color: #69efff;
  // position: absolute !important;
  .popper__arrow {
    border-bottom-color: #69efff;
    &::after {
      border-bottom-color: #031e24;
    }
  }
  .el-select-dropdown__item {
    color: rgba(69, 227, 237, 0.65);
    &.selected {
      color: #69efff;
    }
  }
  ::v-deep .el-select-dropdown {
    background-color: #031e24;
    border-color: #69efff;
    position: absolute !important;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: rgba(69, 227, 237, 0.2);
  }
  ::v-deep .el-select-dropdown__empty {
    color: #69efff;
  }
}

.base-container {
  background: #031216;
  width: 100%;
  height: 100%;
  color: #fff;
  min-width: 1200px;
}
.container {
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  background: #031216;
  .header {
    margin: auto;
    background-image: url('../../assets/dataDashboard/topnav@2x.png');
    width: 100%;
    height: 134px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: -46px;
    position: relative;
  }
  .footer {
    margin: auto;
    background-image: url('../../assets/dataDashboard/footer@2x.png');
    width: 100%;
    height: 36px !important;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 10;
  }
  .bottom-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .bottom-bg-top {
      width: 100%;
      height: 260px;
      position: absolute;
      background: linear-gradient(180deg, #031216 0%, rgba(3, 18, 22, 0) 100%);
      top: 0;
    }
    .bottom-bg-bottom {
      width: 100%;
      height: 400px;
      background: linear-gradient(360deg, #031216 0%, rgba(3, 18, 22, 0) 100%);
      position: absolute;
      bottom: 0;
    }
    .bottom-bg-left {
      width: 660px;
      height: 100%;
      position: absolute;
      background: linear-gradient(270deg, rgba(3, 18, 22, 0) 0%, #031216 100%);
      left: 0;
    }
    .bottom-bg-right {
      width: 660px;
      height: 100%;
      position: absolute;
      background: linear-gradient(270deg, #031216 59%, rgba(3, 18, 22, 0) 100%);
      right: 0;
    }
  }
  .map-bg {
    background-image: url('../../assets/dataDashboard/map@2x.png');
    transition: opacity 0.8s ease;
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .modal-bg {
    background-image: url('../../assets/dataDashboard/modal@2x.png');
    width: calc(100% - 280px);
    height: calc(100% - 54px);
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .data-main-img {
    width: 120px;
    height: 120px;
  }
  .data-mini-img {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }
  .data-main-item {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 30%;
    padding-left: 64px;
    // justify-content: center;
    .data-main-number {
      font-family: D-DIN-PRO, D-DIN-PRO Bold;
      font-weight: 600;
      font-size: 32px;
      color: #75e6f3;
      line-height: 46px;
      display: flex;
      align-items: baseline;
      span:first-child {
        display: inline-block;
        // min-width: 100px;
      }
      span:last-child {
        font-size: 16px;
        margin-left: 4px;
        font-weight: 400;
      }
    }
    .data-main-label {
      font-weight: 400;
      font-size: 18px;
      color: rgba(255, 255, 255, 0.85);
      line-height: 28px;
    }
    .data-main-suffix {
      font-weight: 400;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.65);
      margin-top: 10px;
      line-height: 19px;
      span {
        font-weight: 600;
        font-size: 16px;
        color: #75e6f3;
        line-height: 19px;
      }
    }
  }
  .panel-main-number {
    font-family: D-DIN, D-DIN-PRO;
    font-weight: 600;
    font-size: 26px;
    color: #75e6f3;
    line-height: 40px;
  }
  .panel-main-suffix {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);
    line-height: 16px;
  }
}
.flex {
  display: flex;
}
.flex-y {
  display: flex;
  flex-direction: column;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.flex-wrap {
  flex-wrap: wrap;
}
</style>
