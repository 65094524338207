<template>
  <div class="main">
    <div class="content-box">
      <el-input
        maxlength="100"
        style="width:150px;"
        placeholder="按参数名称搜索"
        v-model="configName"
      >
      </el-input>
      <el-input
        maxlength="100"
        style="width:150px;margin-left:10px;"
        placeholder="按参数键名搜索"
        v-model="configKey"
      ></el-input>

      <el-button
        slot="suffix"
        @click="searchData"
        type="primary"
        icon="el-icon-search"
        style="border-radius:0 4px 4px 0;margin-right:-5px"
        >搜索</el-button
      >

      <!-- <el-button @click="addHandler" type="primary" style="margin-left:20px;" icon="el-icon-plus">新增</el-button> -->
    </div>
    <div class="content-box">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          type="index"
          label="序号"
          align="center"
          width="80"
        ></el-table-column>

        <el-table-column
          prop="configName"
          label="参数名称"
          align="center"
          min-width="220"
        ></el-table-column>

        <el-table-column
          prop="configValue"
          label="参数键值图片"
          align="center"
          min-width="200"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.configValue.includes('http')" class="img">
              <img :src="scope.row.configValue" />
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="configKey"
          label="参数键名"
          align="center"
          min-width="180"
        ></el-table-column>

        <el-table-column
          prop="configValue"
          label="参数键值"
          align="center"
          min-width="200"
        >
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          align="center"
          min-width="200"
        ></el-table-column>

        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
          min-width="130"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <div class="editCell">
              <el-button type="primary" @click="editCardHandler(scope.row)"
                >编辑</el-button
              >
              <!-- <el-link @click="deteteHandlet(scope.row)">删除</el-link> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        :total="total"
      ></el-pagination>
    </div>
    <el-dialog
      :title="isAdd ? '新增' : '编辑'"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <el-form ref="form" :model="formData" label-width="130px">
        <!-- <VueQuillEditor v-model="content"></VueQuillEditor> -->

        <!-- <el-form-item label="风采内容" prop="content" >
                    <quill-editor v-model="formData.content" :min-height="192" />
                </el-form-item> -->

        <el-form-item class="nav-form-item" label="参数名称" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.configName"
            style="width:300px;"
            placeholder="请输入参数名称"
          />
        </el-form-item>

        <el-form-item class="nav-form-item" label="参数键名" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.configKey"
            style="width:300px;"
            placeholder="请输入参数键名"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="参数键值" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.configValue"
            style="width:300px;"
            placeholder="请输入参数键值"
          />
        </el-form-item>
        <el-form-item class="nav-form-item" label="备注" prop="title">
          <el-input
            maxlength="256"
            v-model="formData.remark"
            style="width:300px;"
            placeholder="请输入备注"
          />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="confimHandler">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { useAdvert } from "@/api/advert";

import { getConfigList, delConfig, addConfig, editConfig } from "@/api/config";
import { getCodesByParent } from "@/api/common";
// import { VueQuillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      imageUrl: "",
      dialogVisible: false,
      operatorId: "",
      date: "",
      isAdd: true,
      search: "",
      tableData: [],
      page: 1,
      pageSize: 10,
      loading: true,
      fileType: "",
      formData: {
        configId: "",
        configName: "",
        configKey: "",
        configValue: "",
        remark: "",
      },
      total: 0,
      configName: "",
      configKey: "",
      configValue: "",
      remark: "",
      pageData: [],
      showFlag: true,
    };
  },
  created() {
    this.getPages();
    this.getDataList();
  },
  methods: {
    handleUploadSuccess(res, file) {
      // this.formData.fileId = res.data.imgID
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$refs.upload.clearFiles();
      (this.fileType = ""), (this.dialogVisible = false);
      this.getDataList();
    },
    searchData() {
      this.page = 1;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      let param = {
        configName: this.configName,
        configKey: this.configKey,
        pageNo: this.page,
        pageSize: this.pageSize,
      };
      getConfigList(param).then((res) => {
        if (res.data.records) {
          this.tableData = [...res.data.records];
          this.total = res.data.total;
        }
        this.loading = false;
      });
    },
    getPages() {
      let param = {
        parentCode: 1,
      };
      getCodesByParent(param).then((res) => {
        console.log(res);
        if (res.data && res.data.length > 0) {
          this.pageData = [...res.data];
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getDataList();
    },
    addHandler() {
      this.isAdd = true;
      this.formData.configName = "";
      this.formData.configKey = "";
      this.formData.configValue = "";
      this.formData.remark = "";
      this.dialogVisible = true;
    },
    confimHandler() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.add();
          } else {
            this.editCard();
          }
        }
      });
    },
    editCardHandler(row) {
      this.isAdd = false;
      this.formData.configId = row.configId;
      this.formData.configName = row.configName;
      this.formData.configKey = row.configKey;
      this.formData.configValue = row.configValue;
      this.formData.remark = row.remark;

      this.dialogVisible = true;
    },
    editCard() {
      var params = this.formData;
      params.showPage = null;
      params.showPageList = null;
      editConfig(params).then((res) => {
        if (res.message.code == -1) {
          this.$message.error(res.data);
        } else {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        }
      });
    },
    add() {
      addConfig(this.formData).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.dialogVisible = false;
          this.getDataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    deteteHandlet(row) {
      this.$confirm("确定删除所选项吗?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let param = {
          configId: row.configId,
        };
        delConfig(param).then((res) => {
          if (res.message.code == 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.getDataList();
          } else {
            this.$message.error(res.data);
          }
        });
      });
    },
    changeStateHandler($event, row) {
      let param = {
        state: $event,
        id: row.id,
      };
      useAdvert(param).then((res) => {
        if (res.message.code == 0) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.getDataList();
        } else {
          this.$message.error(res.data);
        }
      });
    },
  },
  // components: { VueQuillEditor },
  // components: {
  //   VueQuillEditor: VueQuillEditor.quillEditor,
  // },
};
</script>
<style lang="scss" scoped>
.title {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.data {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
}

.img {
  width: 200px;
  height: 50px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
