var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticStyle: { display: "flex" } }, [
    _c("div", { staticClass: "data-main-item" }, [
      _c("img", {
        staticClass: "data-main-img",
        attrs: {
          src: require("@/assets/dataDashboard/累计充电量@2x.png"),
          mode: "scaleToFill",
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { flex: "1" } }, [
        _c("div", { staticClass: "data-main-label" }, [_vm._v("累计充电量")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-main-number" },
          [
            _c(_setup.countTo, {
              attrs: {
                endVal: +_setup.formatLargeNumber(_vm.data.chargeCapacity) || 0,
                duration: _vm.duration,
                decimals: 3,
              },
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _setup.getChineseMoneyUnit(_vm.data.chargeCapacity).suffix
                ) + "度"
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "data-main-suffix" }, [
          _vm._v("\n        今日充电量\n        "),
          _c(
            "span",
            [
              _c(_setup.countTo, {
                attrs: {
                  endVal:
                    +_setup.formatLargeNumber(_vm.data.todayChargeCapacity) ||
                    0,
                  decimals: 3,
                  duration: _vm.duration,
                },
              }),
              _vm._v(
                "\n          " +
                  _vm._s(
                    _setup.getChineseMoneyUnit(_vm.data.todayChargeCapacity)
                      .suffix
                  ) +
                  "度\n        "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "data-main-item" }, [
      _c("img", {
        staticClass: "data-main-img",
        attrs: {
          src: require("@/assets/dataDashboard/累计充电营收@2x.png"),
          mode: "scaleToFill",
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { flex: "1" } }, [
        _c("div", { staticClass: "data-main-label" }, [_vm._v("累计充电营收")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-main-number" },
          [
            _c(_setup.countTo, {
              attrs: {
                endVal: +_setup.formatLargeNumber(_vm.data.chargeAmount) || 0,
                duration: _vm.duration,
                decimals: 2,
              },
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _setup.getChineseMoneyUnit(_vm.data.chargeAmount).suffix
                ) + "元"
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "data-main-suffix" }, [
          _vm._v("\n        今日充电营收\n        "),
          _c(
            "span",
            [
              _c(_setup.countTo, {
                attrs: {
                  endVal:
                    +_setup.formatLargeNumber(_vm.data.todayChargeAmount) || 0,
                  decimals: 2,
                  duration: _vm.duration,
                },
              }),
              _vm._v(
                "\n          " +
                  _vm._s(
                    _setup.getChineseMoneyUnit(_vm.data.todayChargeAmount)
                      .suffix
                  ) +
                  "元"
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "data-main-item" }, [
      _c("img", {
        staticClass: "data-main-img",
        attrs: {
          src: require("@/assets/dataDashboard/累计充电服务费@2x.png"),
          mode: "scaleToFill",
        },
      }),
      _vm._v(" "),
      _c("div", { staticStyle: { flex: "1" } }, [
        _c("div", { staticClass: "data-main-label" }, [
          _vm._v("累计充电服务费"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "data-main-number" },
          [
            _c(_setup.countTo, {
              attrs: {
                endVal: +_setup.formatLargeNumber(_vm.data.serviceAmount) || 0,
                duration: _vm.duration,
                decimals: 2,
              },
            }),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                _vm._s(
                  _setup.getChineseMoneyUnit(_vm.data.serviceAmount).suffix
                ) + "元"
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "data-main-suffix" }, [
          _vm._v("\n        今日充电服务费\n        "),
          _c(
            "span",
            [
              _c(_setup.countTo, {
                attrs: {
                  endVal:
                    +_setup.formatLargeNumber(_vm.data.todayServiceAmount) || 0,
                  decimals: 2,
                  duration: _vm.duration,
                },
              }),
              _vm._v(
                "\n          " +
                  _vm._s(
                    _setup.getChineseMoneyUnit(_vm.data.todayServiceAmount)
                      .suffix
                  ) +
                  "元\n        "
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }