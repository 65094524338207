<template>
  <div @wheel="goWheel" style="height: 100%;width: 100%;">
    <div
      v-if="dataArr.length !== 0"
      style="height: 100%;width: 100%;overflow: hidden;padding: 24px 16px 16px 6px;"
    >
      <el-row style="margin-bottom: 16px;">
        <el-col :span="3" class="header" style="text-align:center">
          排名
        </el-col>
        <el-col :span="8" class="header">站点</el-col>
        <el-col :span="9" class="header">时间利用率</el-col>
        <el-col :span="4" class="header" style="text-align:right">环比</el-col>
      </el-row>
      <el-carousel
        ref="carousel"
        direction="vertical"
        :autoplay="true"
        loop
        indicator-position="none"
        arrow="never"
        :interval="4000"
      >
        <el-carousel-item v-for="(dataItem, i) in dataArr" :key="i">
          <el-row
            class="item"
            v-for="(item, index) in dataItem"
            :key="item.plotName + index"
          >
            <el-col :span="3" class="list-item rank-index">
              <img
                v-if="item.index === 1"
                src="@/assets/dataDashboard/the-first.png"
                class="medal-icon"
              />
              <img
                v-else-if="item.index === 2"
                src="@/assets/dataDashboard/the-second.png"
                class="medal-icon"
              />
              <img
                v-else-if="item.index === 3"
                src="@/assets/dataDashboard/the-third.png"
                class="medal-icon"
              />
              <span v-else>{{ item.index }}</span>
            </el-col>
            <el-col :span="8" class="list-item">
              <div style="margin-right: 10px">
                <OverflowText :text="item.plotName"></OverflowText>
              </div>
            </el-col>
            <el-col
              :span="9"
              class="list-item"
              style="display: flex;align-items: center;"
              ><span style="min-width: 46px;display: inline-block;"
                >{{ item.utilizationRate || "-" }}%</span
              >
              <el-progress
                style="display: inline-block;width: 100%; margin-left: 6px;"
                :percentage="
                  +item.utilizationRate > 100 ? 100 : +item.utilizationRate
                "
                define-back-color="rgba(5, 124, 152, 0.08)"
                :show-text="false"
              ></el-progress>
            </el-col>
            <el-col
              :span="4"
              class="list-item"
              style="text-align: right;display: flex; align-items: center;justify-content: flex-end;"
            >
              <span
                :style="{
                  color: chainTextColor(item.periodicChange),
                }"
                style="text-wrap: nowrap;overflow: hidden;text-overflow: ellipsis;"
                v-overflow-tooltip
                >{{ item.periodic || "-" }}%</span
              >
              <img
                v-if="+item.periodicChange !== 0"
                :src="
                  item.periodicChange > 0
                    ? require('@/assets/dataDashboard/up.png')
                    : require('@/assets/dataDashboard/down.png')
                "
                class="trend-icon"
              />
            </el-col>
          </el-row>
        </el-carousel-item>
      </el-carousel>
    </div>

    <el-empty :image="emptyImg" v-else description="暂无数据" />
  </div>
</template>

<script setup>
import { defineProps, computed, ref, defineExpose } from "vue";
import OverflowText from "@/components/OverflowText/index.vue";
import { formatToThousand } from "../utils/unitUtil";
const props = defineProps({
  data: {
    type: Array,
    default: () => {
      return [];
    },
  },
});
let emptyImg = ref(require("@/assets/dataDashboard/空态@2x.png"));
// 数组分成6条一页，返回二维数组
const dataArr = computed(() => {
  const arr = [];
  props.data.forEach((item, i) => {
    item.index = i + 1;
    item.periodic = formatToThousand(item.periodicChange, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 2,
    });
  });
  for (let i = 0; i < props.data.length; i += 6) {
    arr.push(props.data.slice(i, i + 6));
  }
  return arr;
});
let carousel = ref();
let resetCarousel = () => {
  carousel.value.setActiveItem(0);
};
defineExpose({
  resetCarousel,
});
let chainTextColor = (val) => {
  if (val > 0) {
    return "#FF685E";
  } else if (val < 0) {
    return "#3FF5B3";
  }
  return;
};
let one = ref(true);
function goWheel() {
  if (event.deltaY > 0 && one.value == true) {
    //data中定义one为true 当one为true时执行
    carousel.value.next(); //以此来控制每次轮播图切换的张数
    one.value = false;
    setTimeout(() => {
      one.value = true;
    }, 1000);
  }

  if (event.deltaY < 0 && one.value == true) {
    carousel.value.prev();
    one.value = false;
    setTimeout(() => {
      one.value = true;
    }, 1000);
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.45);
  line-height: 16px;
}
.list-item {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
}
.rank-index {
  font-family: D-DIN-PRO;
  font-weight: bold;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.65);
  line-height: 22px;
  text-align: center;
}
.trend-icon {
  height: 12px;
  width: 12px;
  display: inline;
}
::v-deep .el-progress-bar__inner {
  background: linear-gradient(90deg, #01b9f9 0%, #18d0c5 100%);
}
.medal-icon {
  height: 24px;
  width: 24px;
}
.item {
  margin-bottom: 10px;
  line-height: 24px;
}
::v-deep .el-empty {
  height: 100%;
}
::v-deep .el-empty__description {
  margin-top: 0;
  p {
    font-size: 14px;
    color: rgba(117, 230, 243, 0.35);
  }
}
::v-deep .el-empty__image {
  width: 120px;
  height: 100px;
}
</style>
