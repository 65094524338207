import Decimal from "decimal.js";
export function getChineseMoneyUnit(num) {
  // 值为 0 或者 null
  if (!num) {
    return { divide: 1, suffix: "" };
  }
  const units = [
    { threshold: 10000 * 10000 * 10000, suffix: "万亿" },
    { threshold: 10000 * 10000, suffix: "亿" },
    { threshold: 10000 * 100, suffix: "万" },
    { threshold: 0, divide: 1, suffix: "" },
  ].map((it) => {
    if (!it.divide) {
      it.divide = it.threshold;
    }
    return it;
  });

  const index = units.findIndex((it) => Math.abs(num) >= it.threshold);
  return units[index];
}

export function formatLargeNumber(value) {
  if (value) {
    const decimalValue = new Decimal(value);

    if (decimalValue.gte(100000000)) {
      return decimalValue.div(100000000).toFixed(2);
    } else if (decimalValue.gte(1000000)) {
      return decimalValue.div(10000).toFixed(2);
    }
  }
  return value;
}

export function formatToThousand(num, option = {}) {
  if (!num) return num;
  return new Intl.NumberFormat("en-US", option).format(num);
}
