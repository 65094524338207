var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "base-container" },
    [
      _c(
        _setup.VisualizedDashboardContainer,
        [
          _c(
            "el-container",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "modal-bg" }),
              _vm._v(" "),
              _c("transition", { attrs: { name: "fade" } }, [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_setup.plotId,
                      expression: "!plotId",
                    },
                  ],
                  staticClass: "map-bg",
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bottom-bg" }, [
                !_setup.plotId
                  ? _c(
                      "div",
                      {
                        staticStyle: {
                          height: "100%",
                          width: "100%",
                          position: "relative",
                        },
                      },
                      [
                        _c("div", { staticClass: "bottom-bg-top" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-bg-right" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-bg-bottom" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "bottom-bg-left" }),
                      ]
                    )
                  : _c("div", {
                      staticStyle: {
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        "background-color": "rgba(3, 18, 22, 0.40)",
                      },
                    }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header" }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      position: "absolute",
                      left: "24px",
                      top: "13px",
                      display: "flex",
                    },
                  },
                  [
                    !_setup.isOperator
                      ? _c(
                          "div",
                          {
                            staticClass: "data-selector",
                            staticStyle: { "margin-right": "20px" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "运营商名称",
                                  filterable: "",
                                  clearable: "",
                                  "popper-class": "popper-class",
                                  "popper-append-to-body": false,
                                },
                                on: {
                                  change: _setup.handleOperChange,
                                  clear: _setup.handleOperClear,
                                },
                                model: {
                                  value: _setup.operId,
                                  callback: function ($$v) {
                                    _setup.operId = $$v
                                  },
                                  expression: "operId",
                                },
                              },
                              _vm._l(_setup.operatorList, function (item) {
                                return _c("el-option", {
                                  key: item.operatorId,
                                  attrs: {
                                    label: item.operatorNm,
                                    value: item.operatorId,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "data-selector" },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "站点名称",
                              filterable: "",
                              clearable: "",
                              "popper-class": "popper-class",
                              "popper-append-to-body": false,
                            },
                            on: {
                              change: _setup.handlePlotChange,
                              clear: _setup.handlePlotClear,
                            },
                            model: {
                              value: _setup.plotId,
                              callback: function ($$v) {
                                _setup.plotId = $$v
                              },
                              expression: "plotId",
                            },
                          },
                          _vm._l(_setup.plotList, function (item) {
                            return _c("el-option", {
                              key: item.id,
                              attrs: { label: item.plotName, value: item.id },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-container",
                [
                  _c(
                    "el-aside",
                    {
                      staticStyle: {
                        overflow: "visible",
                        "padding-left": "24px",
                      },
                      attrs: { width: "477px" },
                    },
                    [
                      _c(
                        _setup.BorderBox,
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { title: "经营总览" },
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticClass: "flex flex-wrap",
                              staticStyle: {
                                "align-items": "center",
                                height: "100%",
                                padding: "10px 10px 20px 10px",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                {
                                  staticClass: "flex-y flex-center",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-number" },
                                    [
                                      _c(_setup.countTo, {
                                        attrs: {
                                          endVal:
                                            +_setup.overviewInfo.stationTotal ||
                                            0,
                                          duration: _setup.duration,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-suffix" },
                                    [_vm._v("电站总数 (个)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              !_setup.plotId
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "flex-y flex-center",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-number" },
                                        [
                                          _c(_setup.countTo, {
                                            attrs: {
                                              endVal:
                                                +_setup.overviewInfo
                                                  .chargeTerminal || 0,
                                              duration: _setup.duration,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-suffix" },
                                        [_vm._v("充电终端 (个)")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_setup.plotId
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "flex-y flex-center",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-number" },
                                        [
                                          _c(_setup.countTo, {
                                            attrs: {
                                              endVal:
                                                +_setup.overviewInfo
                                                  .acTerminal || 0,
                                              duration: _setup.duration,
                                            },
                                          }),
                                          _vm._v("/\n                  "),
                                          _c(_setup.countTo, {
                                            attrs: {
                                              endVal:
                                                +_setup.overviewInfo
                                                  .dcTerminal || 0,
                                              duration: _setup.duration,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-suffix" },
                                        [_vm._v("交流/直流终端 (个)")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.plotId
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "flex-y flex-center",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-number" },
                                        [
                                          _c(_setup.countTo, {
                                            attrs: {
                                              endVal:
                                                +_setup.overviewInfo
                                                  .acTerminal || 0,
                                              duration: _setup.duration,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-suffix" },
                                        [_vm._v("交流终端 (个)")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _setup.plotId
                                ? _c(
                                    "el-col",
                                    {
                                      staticClass: "flex-y flex-center",
                                      attrs: { span: 8 },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-number" },
                                        [
                                          _c(_setup.countTo, {
                                            attrs: {
                                              endVal:
                                                +_setup.overviewInfo
                                                  .dcTerminal || 0,
                                              duration: _setup.duration,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "panel-main-suffix" },
                                        [_vm._v("直流终端 (个)")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "flex-y flex-center",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-number" },
                                    [
                                      _c(_setup.countTo, {
                                        attrs: {
                                          endVal:
                                            +_setup.overviewInfo
                                              .chargeServiceCount || 0,
                                          duration: _setup.duration,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-suffix" },
                                    [_vm._v("累计充电服务 (次)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "flex-y flex-center",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-number" },
                                    [
                                      _c(_setup.countTo, {
                                        attrs: {
                                          endVal:
                                            +_setup.overviewInfo.serviceCount ||
                                            0,
                                          duration: _setup.duration,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-suffix" },
                                    [_vm._v("累计服务车主 (人)")]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-col",
                                {
                                  staticClass: "flex-y flex-center",
                                  attrs: { span: 8 },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-number" },
                                    [
                                      _c(_setup.countTo, {
                                        attrs: {
                                          endVal:
                                            +_setup.overviewInfo.reduceCarbon ||
                                            0,
                                          duration: _setup.duration,
                                          decimals: 2,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "panel-main-suffix" },
                                    [_vm._v("减少碳排放 (吨)")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        _setup.BorderBox,
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _setup.chartChargingTrendLoading,
                              expression: "chartChargingTrendLoading",
                            },
                          ],
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            height: "337px",
                            title: "充电趋势",
                            showFilter: "",
                            filterGroup: _setup.todayFilterGroups,
                            "element-loading-background":
                              "rgba(3, 18, 22, 0.4)",
                          },
                          on: {
                            filterChange:
                              _setup.handleChargingTrendFilterChange,
                          },
                        },
                        [
                          _c(_setup.VChart, {
                            ref: "chartChargingTrend",
                            staticStyle: { height: "100%" },
                            attrs: {
                              option: _setup.chartChargingTrendOption,
                              theme: "dataPanelTheme",
                              autoresize: "",
                              "manual-update": true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        _setup.BorderBox,
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value:
                                _setup.chartChargingPeriodsDistributionLoading,
                              expression:
                                "chartChargingPeriodsDistributionLoading",
                            },
                          ],
                          attrs: {
                            height: "337px",
                            title: "充电时段分布",
                            filterGroup: _setup.lastDayFilterGroups,
                            "element-loading-background":
                              "rgba(3, 18, 22, 0.4)",
                            showFilter: "",
                          },
                          on: {
                            filterChange:
                              _setup.handleChargingPeriodsDistributionFilterChange,
                          },
                        },
                        [
                          _c(_setup.VChart, {
                            ref: "chartChargingPeriodsDistribution",
                            staticStyle: { height: "100%" },
                            attrs: {
                              option:
                                _setup.chartChargingPeriodsDistributionOption,
                              theme: "dataPanelTheme",
                              autoresize: "",
                              "manual-update": true,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-container",
                    [
                      _c(
                        "el-main",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "column",
                            "z-index": "1",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                flex: "1",
                                width: "100%",
                                height: "100%",
                              },
                            },
                            [
                              !_setup.plotId
                                ? _c(_setup.accumulatePanel, {
                                    staticStyle: {
                                      display: "flex",
                                      "padding-top": "31px",
                                      margin: "0 25px",
                                    },
                                    attrs: { data: _setup.overviewInfo },
                                  })
                                : _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c(
                                        _setup.BorderBox,
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                            "margin-top": "-20px",
                                            width: "100%",
                                          },
                                          attrs: { showHeader: false },
                                        },
                                        [
                                          _c(_setup.accumulatePanel, {
                                            staticStyle: {
                                              display: "flex",
                                              "align-items": "center",
                                              height: "100%",
                                              margin: "0 24px",
                                            },
                                            attrs: {
                                              data: _setup.overviewInfo,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _setup.plotId
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _c(
                                        _setup.BorderBox,
                                        {
                                          staticStyle: {
                                            "margin-bottom": "20px",
                                            width: "100%",
                                          },
                                          attrs: {
                                            height: "337px",
                                            title: "经营效率",
                                            showFilter: "",
                                            filterGroup:
                                              _setup.lastDayFilterGroups,
                                          },
                                          on: {
                                            filterChange:
                                              _setup.handleBusinessEfficiencyFilterChange,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex flex-wrap",
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%",
                                                padding: "20px 0",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/时间利用率@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .timeUtilization ||
                                                              0,
                                                            decimals: 1,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v("%"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [_vm._v("时间利用率")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/单均充电量@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .orderAvgCharge ||
                                                              0,
                                                            decimals: 3,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span"),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [_vm._v("单均充电量(度)")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/枪日均充电量@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .gunAvgCharge ||
                                                              0,
                                                            decimals: 3,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span"),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "枪日均充电量(度)"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/功率利用率@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .powerUtilization ||
                                                              0,
                                                            decimals: 1,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v("%"),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [_vm._v("功率利用率")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/单均服务费@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .orderAvgService ||
                                                              0,
                                                            decimals: 2,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span"),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [_vm._v("单均服务费(元)")]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "data-main-item",
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass:
                                                      "data-mini-img",
                                                    attrs: {
                                                      src: require("@/assets/dataDashboard/枪日均服务费@2x.png"),
                                                      mode: "scaleToFill",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-number",
                                                      },
                                                      [
                                                        _c(_setup.countTo, {
                                                          attrs: {
                                                            endVal:
                                                              +_setup
                                                                .bussinessEfficiencyData
                                                                .gunAvgService ||
                                                              0,
                                                            decimals: 2,
                                                            duration:
                                                              _setup.duration,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("span"),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "data-main-suffix",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "枪日均服务费(元)"
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                height: "337px",
                                display: "flex",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                _setup.BorderBox,
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _setup.chartChargingCapacityLoading,
                                      expression:
                                        "chartChargingCapacityLoading",
                                    },
                                  ],
                                  attrs: {
                                    height: "337px",
                                    title: "充电量分布",
                                    showFilter: "",
                                    filterGroup: _setup.monthFilterGroups,
                                    "element-loading-background":
                                      "rgba(3, 18, 22, 0.4)",
                                  },
                                  on: {
                                    filterChange:
                                      _setup.handleChargingCapacityFilterChange,
                                  },
                                },
                                [
                                  _c(_setup.VChart, {
                                    ref: "chartChargingCapacity",
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      option:
                                        _setup.chartChargingCapacityOption,
                                      theme: "dataPanelTheme",
                                      autoresize: "",
                                      "manual-update": true,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                _setup.BorderBox,
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value:
                                        _setup.chartOrderPriceDistributionLoading,
                                      expression:
                                        "chartOrderPriceDistributionLoading",
                                    },
                                  ],
                                  attrs: {
                                    height: "337px",
                                    title: "订单金额分布",
                                    filterGroup: _setup.monthFilterGroups,
                                    "element-loading-background":
                                      "rgba(3, 18, 22, 0.4)",
                                    showFilter: "",
                                  },
                                  on: {
                                    filterChange:
                                      _setup.handleOrderPriceDistributionFilterChange,
                                  },
                                },
                                [
                                  _c(_setup.VChart, {
                                    ref: "chartOrderPriceDistribution",
                                    staticStyle: { height: "100%" },
                                    attrs: {
                                      option:
                                        _setup.chartOrderPriceDistributionOption,
                                      theme: "dataPanelTheme",
                                      autoresize: "",
                                      "manual-update": true,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-aside",
                    {
                      staticStyle: { "padding-right": "24px" },
                      attrs: { width: "477px" },
                    },
                    [
                      _c(
                        _setup.BorderBox,
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { title: "实时终端状态" },
                        },
                        [
                          _c(_setup.VChart, {
                            ref: "chartRealtimeTerminalStatus",
                            staticStyle: { height: "100%" },
                            attrs: {
                              option: _setup.chartRealtimeTerminalStatusOption,
                              theme: "dataPanelTheme",
                              autoresize: "",
                              "manual-update": true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        _setup.BorderBox,
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _setup.chartPowerTrendLoading,
                              expression: "chartPowerTrendLoading",
                            },
                          ],
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            height: "337px",
                            title: "功率趋势",
                            showFilter: "",
                            filterGroup: _setup.todayFilterGroups,
                            "element-loading-background":
                              "rgba(3, 18, 22, 0.4)",
                          },
                          on: {
                            filterChange: _setup.handlePowerTrendFilterChange,
                          },
                        },
                        [
                          _c(_setup.VChart, {
                            ref: "chartPowerTrendRef",
                            staticStyle: { height: "100%" },
                            attrs: {
                              option: _setup.chartPowerTrendOption,
                              theme: "dataPanelTheme",
                              autoresize: "",
                              "manual-update": true,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_setup.plotId
                        ? _c(
                            _setup.BorderBox,
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _setup.rankListLoading,
                                  expression: "rankListLoading",
                                },
                              ],
                              attrs: {
                                height: "337px",
                                "element-loading-background":
                                  "rgba(3, 18, 22, 0.4)",
                                filterGroup: _setup.lastDayFilterGroups,
                                title: "运营效率",
                                showFilter: "",
                              },
                              on: {
                                filterChange: _setup.handleRankListFilterChange,
                              },
                            },
                            [
                              _c(_setup.RankList, {
                                ref: "rankListRef",
                                attrs: { data: _setup.rankList },
                              }),
                            ],
                            1
                          )
                        : _c(
                            _setup.BorderBox,
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _setup.rankListLoading,
                                  expression: "rankListLoading",
                                },
                              ],
                              attrs: {
                                height: "337px",
                                "element-loading-background":
                                  "rgba(3, 18, 22, 0.4)",
                                title: "车主充电频次",
                                showFilter: "",
                              },
                              on: {
                                filterChange:
                                  _setup.handleChargingFrequencyFilterChange,
                              },
                            },
                            [
                              _c(_setup.VChart, {
                                ref: "chartChargingFrequency",
                                staticStyle: { height: "100%" },
                                attrs: {
                                  option: _setup.chartChargingFrequencyOption,
                                  theme: "dataPanelTheme",
                                  autoresize: "",
                                  "manual-update": true,
                                },
                              }),
                            ],
                            1
                          ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-footer", { staticClass: "footer" }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }