var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticStyle: { height: "100%", width: "100%" },
      on: { wheel: _setup.goWheel },
    },
    [
      _setup.dataArr.length !== 0
        ? _c(
            "div",
            {
              staticStyle: {
                height: "100%",
                width: "100%",
                overflow: "hidden",
                padding: "24px 16px 16px 6px",
              },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "16px" } },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "header",
                      staticStyle: { "text-align": "center" },
                      attrs: { span: 3 },
                    },
                    [_vm._v("\n        排名\n      ")]
                  ),
                  _vm._v(" "),
                  _c("el-col", { staticClass: "header", attrs: { span: 8 } }, [
                    _vm._v("站点"),
                  ]),
                  _vm._v(" "),
                  _c("el-col", { staticClass: "header", attrs: { span: 9 } }, [
                    _vm._v("时间利用率"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    {
                      staticClass: "header",
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 4 },
                    },
                    [_vm._v("环比")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-carousel",
                {
                  ref: "carousel",
                  attrs: {
                    direction: "vertical",
                    autoplay: true,
                    loop: "",
                    "indicator-position": "none",
                    arrow: "never",
                    interval: 4000,
                  },
                },
                _vm._l(_setup.dataArr, function (dataItem, i) {
                  return _c(
                    "el-carousel-item",
                    { key: i },
                    _vm._l(dataItem, function (item, index) {
                      return _c(
                        "el-row",
                        { key: item.plotName + index, staticClass: "item" },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "list-item rank-index",
                              attrs: { span: 3 },
                            },
                            [
                              item.index === 1
                                ? _c("img", {
                                    staticClass: "medal-icon",
                                    attrs: {
                                      src: require("@/assets/dataDashboard/the-first.png"),
                                    },
                                  })
                                : item.index === 2
                                ? _c("img", {
                                    staticClass: "medal-icon",
                                    attrs: {
                                      src: require("@/assets/dataDashboard/the-second.png"),
                                    },
                                  })
                                : item.index === 3
                                ? _c("img", {
                                    staticClass: "medal-icon",
                                    attrs: {
                                      src: require("@/assets/dataDashboard/the-third.png"),
                                    },
                                  })
                                : _c("span", [_vm._v(_vm._s(item.index))]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { staticClass: "list-item", attrs: { span: 8 } },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-right": "10px" } },
                                [
                                  _c(_setup.OverflowText, {
                                    attrs: { text: item.plotName },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "list-item",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                              },
                              attrs: { span: 9 },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "min-width": "46px",
                                    display: "inline-block",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(item.utilizationRate || "-") + "%"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("el-progress", {
                                staticStyle: {
                                  display: "inline-block",
                                  width: "100%",
                                  "margin-left": "6px",
                                },
                                attrs: {
                                  percentage:
                                    +item.utilizationRate > 100
                                      ? 100
                                      : +item.utilizationRate,
                                  "define-back-color":
                                    "rgba(5, 124, 152, 0.08)",
                                  "show-text": false,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            {
                              staticClass: "list-item",
                              staticStyle: {
                                "text-align": "right",
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "flex-end",
                              },
                              attrs: { span: 4 },
                            },
                            [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "overflow-tooltip",
                                      rawName: "v-overflow-tooltip",
                                    },
                                  ],
                                  staticStyle: {
                                    "text-wrap": "nowrap",
                                    overflow: "hidden",
                                    "text-overflow": "ellipsis",
                                  },
                                  style: {
                                    color: _setup.chainTextColor(
                                      item.periodicChange
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(item.periodic || "-") + "%")]
                              ),
                              _vm._v(" "),
                              +item.periodicChange !== 0
                                ? _c("img", {
                                    staticClass: "trend-icon",
                                    attrs: {
                                      src:
                                        item.periodicChange > 0
                                          ? require("@/assets/dataDashboard/up.png")
                                          : require("@/assets/dataDashboard/down.png"),
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _c("el-empty", {
            attrs: { image: _setup.emptyImg, description: "暂无数据" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }