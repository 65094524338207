<template>
  <div class="card-container" :style="{ height, width }">
    <div class="area-max" style="position: absolute;">
      <img
        class="side-border-lt"
        src="@/assets/dataDashboard/conner@2x.png"
        mode="scaleToFill"
      />
      <img
        class="side-border-rt"
        src="@/assets/dataDashboard/conner@2x.png"
        mode="scaleToFill"
      />
      <img
        class="side-border-lb"
        src="@/assets/dataDashboard/conner@2x.png"
        mode="scaleToFill"
      />
      <img
        class="side-border-rb"
        src="@/assets/dataDashboard/conner@2x.png"
        mode="scaleToFill"
      />
    </div>
    <div class="header" v-if="showHeader">
      <span>{{ title }}</span>
      <slot name="filter">
        <span
          class="custom-radio"
          v-if="showFilter"
          style="display: inline-block; "
        >
          <el-radio-group
            v-model="dateShortcutKeys"
            size="mini"
            @change="handleDateShortcutChange"
          >
            <el-radio-button
              v-for="item in filterGroup"
              :key="item.value"
              :label="item.value"
              >{{ item.label }}</el-radio-button
            >
          </el-radio-group>
        </span>
      </slot>
    </div>
    <div class="area-max" style="z-index:10">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
let dateShortcutKeys = ref("-6");
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  width: {
    type: String,
    default: "453px",
  },
  height: {
    type: String,
    default: "222px",
  },
  showFilter: {
    type: Boolean,
    default: false,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  filterGroup: {
    type: Array,
    default: () => [
      {
        label: "近7天",
        value: "-6",
        unit: 0,
      },
      {
        label: "近30天",
        value: "-29",
        unit: 0,
      },
      {
        label: "近12个月",
        value: "-11",
        unit: 1,
      },
    ],
  },
});

watchEffect(() => {
  dateShortcutKeys.value = props.filterGroup[0].value;
});

const emit = defineEmits(["filterChange"]);
let handleDateShortcutChange = (e) => {
  emit(
    "filterChange",
    props.filterGroup.find((item) => {
      return item.value === e;
    })
  );
};
</script>

<style lang="scss" scoped>
.card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 100;
  height: 222px;
  width: 453px;
  padding: 1px;
  border-radius: 4px;
  background: rgba(3, 51, 62, 0.2);
  &::before {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;

    border: 1px solid;
    border-image: linear-gradient(
        36deg,
        rgba(52, 175, 194, 0.4),
        rgba(52, 175, 194, 0.2)
      )
      1 1;
  }
  .header {
    color: #75e6f3;
    padding: 0 12px;
    display: flex;
    align-items: center;
    height: 40px;
    flex-shrink: 0;
    z-index: 101;
    position: relative;
    justify-content: space-between;
    background: linear-gradient(
      180deg,
      rgba(6, 83, 100, 0) 39%,
      rgba(6, 83, 100, 0.3) 100%
    );
    border-radius: 0px 0px 0px 0px;
    &::before {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      top: 40px;
      border-bottom: 1px solid;
      border-image: linear-gradient(
          90deg,
          rgba(12, 168, 202, 0),
          rgba(12, 168, 202, 0.4),
          rgba(12, 168, 202, 0)
        )
        1 1;
    }
  }
}

.custom-radio {
  ::v-deep .el-radio-group {
    background: rgba(255, 255, 255, 0.02) !important;
    border-radius: 4px;
  }
  ::v-deep .el-radio-button {
    padding: 3px !important;
    &::after {
      content: ""; /* 莫得东西，边框嘛，不需要内容 */
      display: block;
      width: 1px;
      height: 20px;
      position: absolute;
      top: 6px;
      right: 0;
      background: rgba(255, 255, 255, 0.04);
    }
    &:last-child::after {
      display: none;
    }
  }
  ::v-deep .el-radio-button__inner {
    border: none !important;
    color: rgba(255, 255, 255, 0.65) !important;
    background-color: transparent !important;
    padding: 4px 8px;
  }
  ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    border-color: transparent !important;
    box-shadow: none !important;
    background-color: rgba(255, 255, 255, 0.04) !important;
    color: #69efff !important;
    border: none !important;
    border-radius: 4px;
  }
}
.side-border-lt {
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 64px;
}
.side-border-rt {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  rotate: 90deg;
}
.side-border-lb {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 64px;
  rotate: 270deg;
}
.side-border-rb {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 64px;
  height: 64px;
  rotate: 180deg;
}
.area-max {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
