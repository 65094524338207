var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "card-container",
      style: { height: _vm.height, width: _vm.width },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.showHeader
        ? _c(
            "div",
            { staticClass: "header" },
            [
              _c("span", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _vm._t("filter", function () {
                return [
                  _vm.showFilter
                    ? _c(
                        "span",
                        {
                          staticClass: "custom-radio",
                          staticStyle: { display: "inline-block" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "mini" },
                              on: { change: _setup.handleDateShortcutChange },
                              model: {
                                value: _setup.dateShortcutKeys,
                                callback: function ($$v) {
                                  _setup.dateShortcutKeys = $$v
                                },
                                expression: "dateShortcutKeys",
                              },
                            },
                            _vm._l(_vm.filterGroup, function (item) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: item.value,
                                  attrs: { label: item.value },
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "area-max", staticStyle: { "z-index": "10" } },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "area-max", staticStyle: { position: "absolute" } },
      [
        _c("img", {
          staticClass: "side-border-lt",
          attrs: {
            src: require("@/assets/dataDashboard/conner@2x.png"),
            mode: "scaleToFill",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "side-border-rt",
          attrs: {
            src: require("@/assets/dataDashboard/conner@2x.png"),
            mode: "scaleToFill",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "side-border-lb",
          attrs: {
            src: require("@/assets/dataDashboard/conner@2x.png"),
            mode: "scaleToFill",
          },
        }),
        _vm._v(" "),
        _c("img", {
          staticClass: "side-border-rb",
          attrs: {
            src: require("@/assets/dataDashboard/conner@2x.png"),
            mode: "scaleToFill",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }