import { omit } from 'lodash-es';
import { Decimal } from 'decimal.js';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { formatToThousand } from '../utils/unitUtil';
dayjs.extend(customParseFormat);
function setJsonArrToSeriesData(data = []) {
  let seriesData = [];
  for (let i = 0; i < data.length; i++) {
    seriesData.push(Object.values(data[i]));
  }
  return seriesData;
}
function getIntervalData(data = []) {
  let value = data.map((item) => item.value);
  // 最大值*2 变个位数 向上取整 /2 变原位数
  let tmpMax = Math.max(...value);
  let tmpMin = Math.min(...value, 0);
  let tmpMaxLen = 10 ** (parseInt(tmpMax).toString().length - 1);
  let tmpMinLen = 10 ** (parseInt(tmpMin).toString().length - 1);
  let max = new Decimal(
    new Decimal(
      Math.ceil(
        new Decimal(Math.ceil(tmpMax)).mul(new Decimal(2).div(tmpMaxLen))
      )
    )
      .div(new Decimal(2))
      .mul(tmpMaxLen)
  ).toNumber();
  let min = new Decimal(
    new Decimal(
      Math.floor(
        new Decimal(Math.floor(tmpMin)).mul(new Decimal(2).div(tmpMinLen))
      )
    )
      .div(new Decimal(2))
      .mul(tmpMinLen)
  ).toNumber();
  let interval = new Decimal(max)
    .sub(new Decimal(min))
    .div(new Decimal(5), 2)
    .toNumber();
  return {
    max,
    min,
    interval,
  };
}

let realtimeTerminalStatusDic = {
  y: '充电中',
  n: '空闲',
  o: '占用',
  f: '故障',
  d: '离线',
};
// 实时终端状态排序
function sortByCustomOrder(arr) {
  const customOrder = Object.keys(realtimeTerminalStatusDic);

  return arr.sort((a, b) => {
    return customOrder.indexOf(a.name) - customOrder.indexOf(b.name);
  });
}

function getMarkPointData(data = []) {
  let markPointData = [];
  let len = data.length;
  if (len) {
    markPointData.push({
      coord: data[len - 1],
      value: parseFloat(data[len - 1][1]).toFixed(1),
    });
  }
  return markPointData;
}
export function useChartOption() {
  // 充电趋势
  let getChartChargingTrendOption = (data) => {
    let { chargeDegree = [], chargeRevenue = [], chargeServiceFee = [] } = data;

    let series1Data = setJsonArrToSeriesData(chargeDegree);
    let series2Data = setJsonArrToSeriesData(chargeServiceFee);
    let series3Data = setJsonArrToSeriesData(chargeRevenue);
    return {
      legend: {
        bottom: '10',
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'inherit',
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              if (item.axisValue.indexOf('-') > -1) {
                htmlText += `<p >${dayjs(item.axisValue, 'M月D日').format(
                  'M月D日'
                )}</p>`;
              } else {
                htmlText += `<p >${item.axisValue}</p>`;
              }
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> <span style="display: inline-block;min-width: 50px;">${
              item.marker
            } ${
              item.seriesName
            }</span> <span style="display: inline-block; font-weight: bold;margin-left: 10px;min-width: 40px;text-align:right;">${formatToThousand(
              item.data[item.encode.y[0]]
            )} ${index === 0 ? '度' : '元'}</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 46,
        right: 30,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          name: '单位 (度)',
          type: 'value',
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
          },
          ...getIntervalData([
            ...chargeDegree,
            ...chargeRevenue,
            ...chargeServiceFee,
          ]),
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
        {
          name: '单位 (元)',
          type: 'value',
          ...getIntervalData([...chargeRevenue, ...chargeServiceFee]),
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
          },
          axisLine: {
            show: false,
          },
          axisLabel: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          data: series1Data,
          name: '充电量',
          type: 'line',
          yAxisIndex: 0,
          showSymbol: false,
          markPoint: {
            data: getMarkPointData(series1Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          tooltip: {
            valueFormatter: (value) => value + ' 度',
          },
          label: {
            color: '#16c5ee',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },

        {
          data: series2Data,
          name: '充电服务费',
          type: 'line',
          yAxisIndex: 1,
          showSymbol: false,
          markPoint: {
            data: getMarkPointData(series2Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          tooltip: {
            valueFormatter: (value) => value + ' 元',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
        {
          data: series3Data,
          name: '充电营收',
          type: 'line',
          yAxisIndex: 1,
          showSymbol: false,
          markPoint: {
            data: getMarkPointData(series3Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          tooltip: {
            valueFormatter: (value) => value + ' 元',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(238, 166, 58, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(238, 166, 58, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  // 充电时段分布
  let getChartChargingPeriodsDistributionOption = (data) => {
    return {
      tooltip: {
        trigger: 'axis',
      },
      grid: {
        top: 46,
        bottom: 46,
        right: 20,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          name: '单位 (度)',
          type: 'value',
          splitNumber: 3,
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          seriesLayoutBy: 'row',
          type: 'line',
          showSymbol: false,
          data: setJsonArrToSeriesData(data),
          tooltip: {
            valueFormatter: (value) => value + ' 度',
          },
          itemStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 1,
              y2: 0,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(16, 203, 228, 1)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(16, 228, 190, 1)', // 100% 处的颜色
                },
              ],
            },
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  let getChartChargingCapacityOption = (data) => {
    return {
      tooltip: {
        trigger: 'axis',
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p >${item.axisValue}度</p>`;
            }
            htmlText += `
            <p style="margin-top: 2px;display: flex;justify-content: space-between"> <span style="display: inline-block;min-width: 50px;">
            ${item.marker}订单数
            </span><span style="display: inline-block; font-weight: bold;margin-left: 10px;min-width: 40px;text-align:right;" >${
              item.value[item.encode.y[0]]
            }笔</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 46,
        bottom: 46,
        right: 30,
      },
      xAxis: {
        type: 'category',
        name: ' 充\n 电\n 量\n(度)',
        nameGap: 0,
        nameTextStyle: {
          lineHeight: 18,
          color: 'rgba(117, 230, 243, .45)',
          rich: {},
          padding: [0, 0, 28, 0],
        },
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          name: '订单数',
          type: 'value',
          splitNumber: 3,
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
            align: 'right',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          seriesLayoutBy: 'row',
          type: 'bar',
          showSymbol: false,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
          },
          tooltip: {
            valueFormatter: (value) => value + ' 笔',
          },
          data: setJsonArrToSeriesData(data),
          barMaxWidth: 22,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  // 订单金额分布
  let getChartOrderPriceDistributionOption = (data) => {
    return {
      tooltip: {
        trigger: 'axis',
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p >${item.axisValue}元</p>`;
            }
            htmlText += `
            <p style="margin-top: 2px;display: flex;justify-content: space-between"> <span style="display: inline-block;min-width: 50px;">
            ${item.marker}订单数
            </span><span style="display: inline-block; font-weight: bold;margin-left: 10px;min-width: 40px;text-align:right;" >${
              item.value[item.encode.y[0]]
            }笔</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 46,
        bottom: 46,
        right: 30,
      },
      xAxis: {
        type: 'category',
        name: ' 金\n 额\n(元)',
        nameGap: 0,
        nameTextStyle: {
          lineHeight: 18,
          color: 'rgba(117, 230, 243, .45)',
          rich: {},
          padding: [0, 0, 10, 0],
        },
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          name: '订单数',
          type: 'value',
          splitNumber: 3,
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
            align: 'right',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          seriesLayoutBy: 'row',
          type: 'bar',
          data: setJsonArrToSeriesData(data),
          tooltip: {
            valueFormatter: (value) => value + ' 笔',
          },
          showSymbol: false,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
            color: '#10e4be',
          },
          barMaxWidth: 22,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  // 功率趋势
  let getChartPowerTrendOption = (data, param) => {
    let { all, ac, dc } = data;
    let series1Data = setJsonArrToSeriesData(all);
    let series2Data = setJsonArrToSeriesData(ac);
    let series3Data = setJsonArrToSeriesData(dc);
    let xAxisSet = new Set();
    return {
      animationDuration: 3000,
      legend: {
        bottom: '10',
        icon: 'circle',
        itemWidth: 10,
        textStyle: {
          color: 'inherit',
        },
      },
      tooltip: {
        trigger: 'axis',
        formatter(params) {
          let htmlText = '';

          params.forEach((item, index) => {
            if (index === 0) {
              if (item.axisValue.indexOf('-') > -1) {
                htmlText += `<p >${dayjs(item.axisValue).format(
                  'M月D日 HH:mm'
                )}</p>`;
              } else {
                htmlText += `<p >${item.axisValue}</p>`;
              }
            }
            htmlText += `<p style="margin-top: 2px;display: flex;justify-content: space-between"> <span style="display: inline-block;min-width: 50px;">${
              item.marker
            } ${
              item.seriesName
            }</span> <span style="display: inline-block; font-weight: bold;margin-left: 10px;min-width: 40px;text-align:right;">${formatToThousand(
              item.data[item.encode.y[0]]
            )} kW</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 46,
        left: 40,
        right: 20,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
        axisLabel: {
          interval: +param?.value ? 0 : 'auto',
          formatter(value, index) {
            if (value.length < 8) {
              return value;
            }
            let formatter = dayjs(value).format('M月D日');

            if (xAxisSet.has(formatter)) {
              return '';
            } else {
              xAxisSet.add(formatter);

              if (series1Data.length < 160) {
                return formatter;
              } else {
                return index % 5 === 0 ? formatter : '';
              }
            }
          },
        },
      },
      yAxis: [
        {
          name: '单位 (kW)',
          type: 'value',
          splitNumber: 3,
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          data: series1Data,
          markPoint: {
            data: getMarkPointData(series1Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          tooltip: {
            valueFormatter: (value) => value + ' kW',
          },
          name: '全部',
          type: 'line',
          yAxisIndex: 0,
          showSymbol: false,
          label: {
            color: '#16c5ee',
          },
          itemStyle: {
            color: 'rgba(238, 166, 58, 1)',
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(238, 166, 58, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(238, 166, 58, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },

        {
          data: series2Data,
          markPoint: {
            data: getMarkPointData(series2Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          name: '直流',
          type: 'line',
          tooltip: {
            valueFormatter: (value) => value + ' kW',
          },
          showSymbol: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
        {
          data: series3Data,
          markPoint: {
            data: getMarkPointData(series3Data),
            itemStyle: {
              opacity: 0.85,
            },
            label: {
              fontWeight: 'bold',
            },
          },
          name: '交流',
          type: 'line',
          tooltip: {
            valueFormatter: (value) => value + ' kW',
          },
          showSymbol: false,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(16, 228, 190, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(16, 228, 190, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  let getChartRealtimeTerminalStatusOption = (data = {}) => {
    let { total = 0 } = data;
    let seriesData = Object.keys(omit(data, ['total'])).map((item) => {
      return {
        name: item,
        value: data[item],
      };
    });
    seriesData = sortByCustomOrder(seriesData);
    return {
      title: {
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter(params) {
          let { marker, data, percent } = params;
          return `${marker} <span style="display: inline-block;min-width: 40px;">${realtimeTerminalStatusDic[
            data.name
          ] || '未知'}</span> <span style="display: inline-block;">${
            data.value
          }</span> <span style="margin-left: 10px;">${parseFloat(
            percent.toFixed(1)
          )}%</span>`;
        },
      },
      legend: {
        height: 300,
        icon: 'circle',
        orient: 'vertical',
        top: 'center',
        itemWidth: 10,
        right: 20,
        formatter: function(name) {
          // 添加
          let total = data.total;
          let target = data[name];

          var arr = [
            '{a|' + (realtimeTerminalStatusDic[name] || '未知') + '}',
            '{b|' + target + '}',
            '{c|' +
              (total > 0
                ? new Decimal(
                    new Decimal(target)
                      .div(total)
                      .toNumber()
                      .toFixed(3)
                  )
                    .mul(100)
                    .toNumber()
                : '-') +
              '%}',
          ];
          return arr.join('  ');
        },
        textStyle: {
          // 添加
          lineHeight: 20,
          rich: {
            a: {
              fontSize: 14,
              width: 40,
              color: 'rgba(255,255,255,0.65)',
            },
            b: {
              fontSize: 14,
              width: 80,
              align: 'right',
              color: 'rgba(255,255,255,0.85)',
            },
            c: {
              fontSize: 14,
              width: 20,
              align: 'right',
              color: 'rgba(255,255,255,0.85)',
            },
          },
        },
      },
      series: [
        {
          type: 'pie',
          radius: 65,
          radius: [60, 70],
          center: [110, '50%'],
          data: seriesData,
          label: {
            show: false,
          },
        },
        {
          name: '全部',
          type: 'pie',
          radius: ['0%', '0%'],
          center: [110, '50%'],
          tooltip: {
            show: false,
          },
          itemStyle: {
            // 防止鼠标悬浮到标签时出现放大的点
            color: 'transparent',
          },
          label: {
            position: 'inside',
            formatter: `{data|{c}}\n{serie|{a}}`,
            rich: {
              data: {
                fontWeight: 'bold',
                fontFamily: 'D-DIN-PRO, D-DIN-PRO Bold',
                fontSize: 24,
                color: `#fff`,
                lineHeight: 30,
                textBorderColor: `transparent`,
                textBorderWidth: 0,
              },
              serie: {
                fontSize: 14,
                color: `#fff`,
                lineHeight: 20,
                textBorderColor: `transparent`,
                textBorderWidth: 0,
              },
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            // 计算表格数据value总和
            total,
          ],
        },
      ],
    };
  };
  let getChartChargingFrequencyOption = (data) => {
    return {
      tooltip: {
        trigger: 'axis',
        formatter(params) {
          let htmlText = '';
          params.forEach((item, index) => {
            if (index === 0) {
              htmlText += `<p >${item.axisValue}次</p>`;
            }
            htmlText += `
            <p style="margin-top: 2px;display: flex;justify-content: space-between"> <span style="display: inline-block;">
            ${item.marker}
            </span><span style="display: inline-block; font-weight: bold;margin-left: 10px;min-width: 20px;text-align:right;" >${
              item.value[item.encode.y[0]]
            }人</span></p>`;
          });
          return htmlText;
        },
      },
      grid: {
        top: 46,
        bottom: 46,
        right: 20,
      },
      xAxis: {
        type: 'category',
        name: '次\n数',
        nameGap: 0,
        nameTextStyle: {
          lineHeight: 18,
          color: 'rgba(117, 230, 243, .45)',
          rich: {},
          padding: [8, 0, 0, 0],
          align: 'center',
        },
        axisLine: { onZero: true, show: false },
        splitLine: {
          show: false,
        },
      },
      yAxis: [
        {
          name: '车主数',
          type: 'value',
          splitNumber: 3,
          nameTextStyle: {
            color: 'rgba(117, 230, 243, .45)',
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: ['#75E6F3'],
              opacity: 0.1,
            },
          },
        },
      ],
      series: [
        {
          seriesLayoutBy: 'row',
          type: 'bar',
          data: setJsonArrToSeriesData(data),
          tooltip: {
            valueFormatter: (value) => value + ' 度',
          },
          showSymbol: false,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
            color: '#12bbb3',
          },
          barMaxWidth: 22,
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(22, 197, 238, 0.30)', // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(22, 197, 238, 0)', // 100% 处的颜色
                },
              ],
            },
          },
        },
      ],
    };
  };
  return {
    getChartChargingTrendOption,
    getChartChargingPeriodsDistributionOption,
    getChartChargingCapacityOption,
    getChartOrderPriceDistributionOption,
    getChartPowerTrendOption,
    getChartRealtimeTerminalStatusOption,
    getChartChargingFrequencyOption,
  };
}
